<template>
  <div id="BrandZone">
    <Header
      id="Header"
      class="BrandZone_Header"
      :background="background"
    ></Header>
    <div class="topBanner">
      <img :src="top_banner" class="my_cover" alt="" />
    </div>
    <Spot
      style="margin-top: 100px; margin-bottom: 92px"
      :SpotZhTitle="SpotZhTitle"
      :SpotEnTitle="SpotEnTitle"
    ></Spot>
    <!-- 百叶图 -->
    <div class="technolog_imgbox">
      <div class="middleContentBoxWrap">
        <div class="middleContentBox">
          <div
            class="contentImgBox"
            :class="choiceImg == index ? 'activeWidth' : ''"
            v-for="(item, index) in imgContent"
            @mouseover="choiceThisImg(index)"
            :key="index"
          >
            <div class="noseclet" v-if="index !== choiceImg"></div>
            <el-image
              :src="item.image"
              style="height: 100%"
              fit="cover"
            ></el-image>
            <div class="seclected" v-if="index === choiceImg">
              <div class="center_box" @click="tolistPage(item,index)">
                <div class="text_center">
                  <span>{{ item.title }}</span>
                  <div class="line_"></div>
                </div>
                <div class="positon_right_bottom">点击查看更多</div>
              </div>
            </div>
            <div class="absoluteBox" v-if="index !== choiceImg">
              {{ item.title }}
            </div>
            <div :class="choiceImg == index ? 'activeborbtm' : 'borbtom'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header";
import Spot from "../../components/spot";
import { getProductGroup } from "../../http/api";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Spot,
  },
  data() {
    return {
      SpotZhTitle: "品牌专区",
      SpotEnTitle: "Brand zone",
      top_banner: "",
      background: {
        "background-color": "transparent",
      },
      imgContent: [],
      choiceImg: 0,
    };
  },
  methods: {
    choiceThisImg(index) {
      this.choiceImg = index;
    },
    tolistPage(item,index){
      this.$router.push({
        path:'/BrandZoneList',
        query:{
          active:index
        }
      })
    }
  },
  mounted() {
    var obj = JSON.parse(localStorage.getItem("getBanner"));
    this.top_banner = obj[0].image;
    getProductGroup().then((res) => {
      this.imgContent = res.data;
    });
  },
};
</script>

<style scoped lang='scss'>
#BrandZone {
  width: 100%;
  .BrandZone_Header {
    width: 100%;
    position: absolute;
    top: 0;
  }
  .topBanner {
    width: 100%;
    // height: 850px;
    height: 44.27vw;
  }
  .technolog_imgbox {
    width: 100%;
    height: 915px;
    max-width: 1920px;
    margin: 0 auto;
    margin-bottom: 138px;
    .middleContentBoxWrap {
      width: 100%;
      height: 100%;
      .middleContentBox {
        display: flex;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        position: relative;
        .contentImgBox:last-child {
          margin-right: 0;
        }
        .contentImgBox {
          flex: 1;
          position: relative;
          border-left: 1px solid white;
          transition: 0.3s all linear;
          overflow: hidden;
          cursor: pointer;
          margin-right: 14px;
          .noseclet {
            position: absolute;
            top: 0;
            bottom: 20px;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.5);
            z-index: 9;
          }
          .seclected {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9;
            .center_box {
              width: 530px;
              // width: 0;
              height: 311px;
              background: rgba(255, 255, 255, 0.8);
              border-radius: 15px;
              // transition: all 0.2s linear;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              .text_center {
                // width: 160px;
                height: 64px;
                font-size: 40px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #225928;
                text-align: center;
                .line_ {
                  width: 71px;
                  height: 1px;
                  background: #225928;
                  margin: 0 auto;
                  margin-top: 11px;
                }
              }
              .positon_right_bottom {
                cursor: pointer;
                width: 111px;
                height: 32px;
                border: 1px solid #225928;
                border-radius: 6px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 32px;
                color: #225928;
                text-align: center;
                position:absolute ;
                bottom: 27px;
                right: 39px;
              }
            }
          }
          // .seclected:hover{
          //   .center_box{
          //     width: 530px;
          //     transition: all 0.2s linear;
          //   }
          // }
          img {
            width: 100%;
            height: 819px;
          }
          .absoluteBox {
            position: absolute;
            bottom: 30px;
            left: 50px;
            font-size: 36px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 37px;
            color: #ffffff;
            text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
          }
          .borbtom {
            position: absolute;
            bottom: -2px;
            height: 21px;
            width: 100%;
            background: #ffffff;
          }
          .activeborbtm {
            position: absolute;
            bottom: -1px;
            height: 21px;
            width: 100%;
            background: #225928;
          }
        }
        .activeWidth {
          flex: 1 1 20%;
        }
      }
    }
  }
}
</style>